<template>
    <div class="work_detail">
        <div class="user_info" v-if="detail.doctorDTO">
            <div class="user_info_avatar">
                <img
                    class="user_info_avatar__inner"
                    :src="detail.doctorDTO.avatar"
                    alt=""
                />
            </div>
            <div class="user_info_content">
                <p class="user_info_content_name">
                    {{ detail.doctorDTO.name }}
                    <span class="user_info_content_name_desc"
                        >{{ detail.doctorDTO.deptName }} |
                        {{ detail.doctorDTO.title }}</span
                    >
                </p>
                <p class="user_info_content_hospital">
                    {{ detail.doctorDTO.hospitalName }}
                </p>
            </div>
        </div>
        <div class="publish_time_bar" v-if="detail.status == 2">
            <div>发布于{{ detail.publishTime.slice(0, 10) }}</div>
            <div>
                {{ $route.query.heatNum || detail.browseNumber }}
                浏览
            </div>
        </div>
        <h2 class="title">{{ detail.title }}</h2>

        <div class="label">
            <p
                v-for="(item, index) in detail.labelList"
                :key="`label-${index}`"
                class="tab"
            >
                <img class="tab__icon" src="@/img/icon-tag.png" alt="" />
                <span>{{ item.label }}</span>
            </p>
        </div>

        <!-- 录课作品详情 -->
        <DetailLuk
            v-if="detail.type == 1"
            :detail="detail"
            @onPause="onPause"
            @onPlay="onPlay"
        />
        <!-- 文章创作组件 -->
        <DetailCreation
            v-if="detail.type == 2"
            :detail="detail"
            @onPause="onPause"
            @onPlay="onPlay"
        />
		<div class="approve" v-if="detail.likeSwitch == 1" @click="handlerApprove">
			<van-icon size="1.3rem" name="good-job-o" v-if="isLike == 0"/>
			<van-icon size="1.3rem" name="good-job" v-else/>
			<span class="text">{{ detail.likeNumber || 0}}</span>
		</div>
    </div>
</template>

<script>
import DetailCreation from "./components/detail-creation.vue";
import DetailLuk from "./components/detail-luk.vue";
import buriedPoint from "../../utils/mixins/buriedPoint";

export default {
	components: { DetailCreation, DetailLuk },
	data: () => ({
		detail: {},
		TimerBuriedPointPlaying: null,
		app_name: "",
		showPopover: false,
		isLike: 0
	}),
	mixins: [buriedPoint],
	computed: {
		md_project_name() {
			return this.$route.params.app_name || "htoh";
		},
		md_send_path() {
			return "work_detail";
		},
		md_send_params() {
			const { id, title, creationDTO, type } = this.detail;
			//保持和原逻辑统一增加live_type判断,后期将统一使用content_type，content_id来替换live_id和live_type
			let live_type, content_type;
			if (this.md_project_name === "hsh") {
				live_type = { 3: "luk", 1: "creation" }[
					creationDTO?.articleType
				];
			} else {
				live_type = { 1: "luk", 2: "creation" }[type];
			}
			/**
			 *  【判断content_type】
			 *   创作类型中分为两种：1图文，3视频
			 *   录课属于视频类
			 */
			if (type === 1) {
				content_type = "video";
			}
			if (type === 2) {
				content_type = { 3: "video", 1: "arctile" }[
					creationDTO?.articleType
				];
			}
			return {
				live_id: id,
				live_name: title,
				live_type,
				content_type,
				content_id: id,
			};
		},
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		async getDetail() {
			const res = await this.$api.work.detail({
				id: this.$route.params.id,
				browseRecord: 1,
			});
			if (!res.code) {
				this.detail = res.data;
        document.title = res.data.title;
				//发送进入埋点
				this.entryPage();
				// 如果是文章则立刻开始记录
				if (this.md_send_params.content_type === "arctile") {
					this.playingBuriedPoint();
				}
				this.getIsLike()
			}
		},
		onPause() {
			console.log("pause");
			this.pauseBuriedPoint();
		},
		onPlay() {
			console.log("playing");
			this.playingBuriedPoint();
		},
		// 是否点赞
		async getIsLike() {
			const res = await this.$api.work.getIsLike(this.$route.params.id)
			if(!res.code) {
				this.isLike = res.data
			}
		},
		async handlerApprove() {
			const res = await this.$api.work.likeArticle(this.$route.params.id)
			if(!res.code) {
				this.detail.likeNumber = res.data
				this.isLike = this.isLike == 0 ? 1 : 0
			}
		}
	},
};
</script>
<style lang="less">
.publish_time_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC;
    font-size: 0.8rem;
    color: #bbbfc4;
    margin-top: 0.8rem;
}
.detail_creation {
    .content_video {
        width: 100%;
    }
}
.van-popover__action-text{
  text-align: center;
}
.approve {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.approve .text {
  font-size: 1.1rem;
  margin-right: 10rx;
}
</style>
