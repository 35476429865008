<template>
    <div class="detail_luk">
        <!-- 整体录制 -->
        <div class="content" v-if="detail.lukDTO.type == 1">
            <div v-for="item in allVideo" :key="item.id">
                <video
                    ref="video"
                    :src="item.videoUrl"
                    :poster="detail.picUlr"
                    @pause="onPause"
                    @play="onPlay"
                    controls
                ></video>
                <p class="video_title">整体视频</p>
            </div>
        </div>
        <!-- 分页录制 -->
        <div class="content" v-else-if="detail.lukDTO.type == 2">
            <div v-for="(item, index) in pageVideo" :key="item.id">
                <video
                    ref="video"
                    :src="item.videoUrl"
                    :poster="detail.picUlr"
                    @pause="onPause"
                    @play="onPlay"
                    controls
                ></video>
                <p class="video_title">视频{{ index + 1 }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        allVideo () {
            return (this.detail.lukDTO.recordList || []).filter(item => item.type === 1)
        },
        pageVideo () {
            return (this.detail.lukDTO.recordList || []).filter(item => item.type === 2)
        }
    },
    mounted () {
        // const self = this
        // const openid = window.localStorage.getItem('openid')
        // this.$refs.video.forEach(item => {
        //   item.addEventListener('play', () => {
        //     if (!openid) return
        //     //  开始播放
        //     self.$buriedPoint.push({
        //       event_type: 'playing',
        //       params: {
        //         live_id: self.detail.recordId || self.detail.id,
        //         live_name: self.detail.title,
        //         live_type: 'luk'
        //       }
        //     })
        //     self.$buriedPoint.send()
        //   })
        //   item.addEventListener('pause', () => {
        //     if (!openid) return
        //     self.$buriedPoint.push({
        //       event_type: 'pause',
        //       params: {
        //         live_id: self.detail.recordId || self.detail.id,
        //         live_name: self.detail.title,
        //         live_type: 'luk'
        //       }
        //     })
        //     self.$buriedPoint.send()
        //   })
        // })
    },
    methods: {
        onPause () {
            this.$emit('onPause')
        },
        onPlay () {
            this.$emit('onPlay')
        },
    }
}
// // 开始上报
// const startBuriedPoint = () => {
//   const { detail } = toRefs(props)
//   buriedPoint.push({
//     event_type: 'luk_play',
//     params: {
//       work_id: detail.value.id,
//       work_type: { 1: 'luk', 2: 'creation' }[detail.value.type],
//     }
//   })
// }

// // 暂停上报
// const buriedPointPause = () => {
//   const { detail } = toRefs(props)
//   this.$buriedPoint.push({
//     event_type: 'luk_pause',
//     params: {
//       work_id: detail.value.id,
//       work_type: { 1: 'luk', 2: 'creation' }[detail.value.type]
//     }
//   })
// }
</script>
