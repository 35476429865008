<template>
	<div class="detail_creation">
		<div class="content" v-if="articleType">
			<!-- <video
				ref="video"
				class="content_video"
				:src="detail.creationDTO.videoUrl"
				@pause="onPause"
				@play="onPlay"
				controls
			></video> -->
      <video
        id="player_video"
				ref="video"
        class="video-js vjs-default-skin vjs-fluid vjs-big-play-centered"
				:src="detail.creationDTO.videoUrl"
				@pause="onPause"
				@play="onPlay"
				controls
			></video>
		</div>
		<div class="content" v-else v-html="content"></div>
	</div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
	props: {
		detail: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		TimerBuriedPointPlaying: null,
    player: null
	}),
	computed: {
		articleType() {
			if (this.detail.creationDTO?.articleType === 3) {
				return true;
			} else {
				return false;
			}
		},
		content() {
			if (this.detail.creationDTO?.content) {
				return this.detail.creationDTO?.content;
			} else {
				return "";
			}
		},
	},
	mounted() {
		// const self = this
		// const openid = window.localStorage.getItem('openid')
		// if (self.detail.creationDTO?.articleType == 3) {
		//   this.$refs.video.addEventListener('play', () => {
		//     if (!openid) return
		//     //  开始播放
		//     this.startBuriedPoint()
		//   })
		//   this.$refs.video.addEventListener('pause', () => {
		//     if (!openid) return
		//     if (this.$route.params.app_name === 'hsh') {
		//       self.$buriedPoint.push({
		//         event_type: 'pause',
		//         event_ts: Date.parse(new Date()),
		//         path: window.location.href,
		//         params: {
		//           live_id: self.detail.id,
		//           live_name: self.detail.creationDTO.title,
		//           live_type: 'luk'
		//         }
		//       })
		//     }
		//     else {
		//       this.$buriedPoint.push({
		//         event_type: 'pause',
		//         params: {
		//           live_id: this.detail.recordId || this.detail.id,
		//           live_name: this.detail.title,
		//           live_type:  'luk'
		//         }
		//       })
		//     }
		//     clearInterval(this.TimerBuriedPointPlaying)
		//     self.$buriedPoint.send()
		//   })
		// }

    this.initVideo(this.detail.creationDTO.videoUrl);
	},
	methods: {
    initVideo(videoUrl, type) {
      this.myPlyer = videojs('player_video', {
          autoplay: false, // 设置自动播放
          controls: true, // 显示播放的控件
          fullscreen: false,
          notSupportedMessage: "",
          sources: [{
            src: videoUrl
          }]

      }, function onPlayerReady () {
        const el = this.el();
        const el_video = el.querySelector('video');
        if (el_video) {
            el_video.setAttribute('webkit-playsinline', 'true');
            el_video.setAttribute('playsinline', 'true');
            el_video.setAttribute('x5-playsinline', '');
            el_video.setAttribute('x-webkit-airplay', 'allow');
        }

        // this.on('play', this.play.bind(this));
        // this.on('pause', this.pause.bind(this));
        // this.on('ended', this.ended.bind(this));
        // this.on('error', this.error.bind(this));
      });
    },

    play() {
      console.log('开始播放');
    },

    pause() {
      console.log('暂停播放')
    },

    ended() {
      console.log('播放结束')
    },

    error() {
      console.log('播放出错')
    },

    dispose() {
      this.myPlyer && this.myPlyer.dispose();
    },
		reset (url, type = 'application/x-mpegURL') {
			this.myPlyer.reset()
			this.myPlyer.src({ type, src: url })
			this.myPlyer.load(url)
			this.myPlyer.play()
		},
		onPause() {
			this.$emit("onPause");
		},
		onPlay() {
			this.$emit("onPlay");
		},
	},
};
</script>
<style lang="less">
.detail_creation {
	.content_video {
		width: 100%;
	}
	.content {
		img {
			max-width: 80%;
      object-fit: contain;
		}
	}
}
</style>
